import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import Loading from '~/components/Loading';

const Dashboard = lazy(() => import('../pages/Dashboard'));
const ItemsList = lazy(() => import('../pages/Items/List'));
const ItemRecord = lazy(() => import('../pages/Items/Record'));
const FoodParcelsList = lazy(() => import('../pages/FoodParcels/List'));
const FoodParcelRecord = lazy(() => import('../pages/FoodParcels/Record'));
const UsersList = lazy(() => import('../pages/Users/List'));
const UserRecord = lazy(() => import('../pages/Users/Record'));
const UserEditUserType = lazy(() => import('../pages/Users/EditUserType'));
const UserChooseFoodParcel = lazy(
  () => import('../pages/Users/ChooseFoodParcel'),
);
// const UserChangePassword = lazy(() => import('../pages/Users/ChangePassword'));

export default function AppRoutes() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route index element={<Dashboard />} />
        <Route path="/items" element={<ItemsList />} />
        <Route path="/items/new" element={<ItemRecord />} />
        <Route path="/items/edit" element={<ItemRecord />} />
        <Route path="/foodparcels" element={<FoodParcelsList />} />
        <Route path="/foodparcels/new" element={<FoodParcelRecord />} />
        <Route path="/foodparcels/edit" element={<FoodParcelRecord />} />
        <Route path="/users" element={<UsersList />} />
        <Route path="/users/edit" element={<UserRecord />} />
        <Route path="/users/edit/usertype" element={<UserEditUserType />} />
        <Route
          path="/users/edit/foodparcel"
          element={<UserChooseFoodParcel />}
        />
        {/* <Route path="/users/edit/password" element={<UserChangePassword />} /> */}
      </Routes>
    </Suspense>
  );
}
