import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import api from '~/services/api';

export interface IUser {
  id: string;
  name: string;
  user_type: {
    name: string;
  };
  department: {
    organization: {
      id: string;
      tag: string;
      location: {
        latitude: string;
        longitude: string;
        name: string;
        street: string;
        number: string;
        district: string;
        city: string;
        state: string;
      };
    };
  };
}

interface ICredentials {
  organization: string;
  registration: number;
  password: string;
}

interface IAuthState {
  user: IUser;
  token: string;
}

interface IAuthContext {
  user: IUser;
  signIn: (credentials: ICredentials) => Promise<void>;
  signOut: () => Promise<void>;
}

interface IAuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

function AuthProvider({ children }: IAuthProviderProps) {
  const [data, setData] = useState<IAuthState>({} as IAuthState);

  useEffect(() => {
    const loadUser = async () => {
      const user = localStorage.getItem('@GuararapesBeneficios:user');
      const token = localStorage.getItem('@GuararapesBeneficios:token');

      if (user && token) {
        api.defaults.headers.common.Authorization = `Bearer ${token}`;

        setData({ user: JSON.parse(user), token });
      }
    };

    loadUser();
  }, []);

  const signIn = useCallback(
    async ({ organization, registration, password }: ICredentials) => {
      const response = await api.post('/sessions', {
        organization,
        registration,
        password,
        admin: true,
      });

      const { user, token, refreshToken } = response.data;

      api.defaults.headers.common.Authorization = `Bearer ${token}`;

      localStorage.setItem('@GuararapesBeneficios:user', JSON.stringify(user));
      localStorage.setItem('@GuararapesBeneficios:token', token);
      localStorage.setItem('@GuararapesBeneficios:refreshtoken', refreshToken);

      setData({ user, token });
    },
    [],
  );

  const signOut = useCallback(async () => {
    localStorage.removeItem('@GuararapesBeneficios:user');
    localStorage.removeItem('@GuararapesBeneficios:token');
    localStorage.removeItem('@GuararapesBeneficios:refreshtoken');

    setData({} as IAuthState);

    window.location.replace('/');
  }, []);

  const value = useMemo(
    () => ({ user: data.user, signIn, signOut }),
    [data, signIn, signOut],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth(): IAuthContext {
  const context = useContext(AuthContext);

  return context;
}

export { AuthProvider, useAuth };
