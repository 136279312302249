export default {
  colors: {
    primary: '#ECD2A6',
    secondary: '#9A6517',
    tertiary: '#88550A',

    background: '#e4e4e4',
    backgroundContainer: '#e7e7e7',
    breadCrumbBackground: '#d7d7d7',
    foodParcelBackground: '#d19b4a',

    input: '#f0f0f0',
    inputBorder: '#cacaca',
    loginInput: '#E5D6C5',
    selectHover: '#ADADAD',
    selected: '#6c757d',
    label: '#2D2D2C',
    labelInput: '#80849e',
    title: '#2D2D2C',
    text: '#2D2D2C',
    icon: '#CEB79F',
    table: '#d7d7d7',
    foodParcelTable: '#d19b4a',
    hoverTable: '#f9f9f9',
    rowSeparator: '#d7d7d7',

    error: '#ff0000',
    save: '#0292ad',
    exit: '#f97c5c',
    edit: '#0292ad',
    danger: '#d6360a',
    warning: '#f7734e',
    cancelOption: '#c6821e',
    lock: '#fcb72c',
    unlock: '#fcb72c',
    addRow: '#0292ad',
    deleteRow: '#d6360a',
    selectOption: '#f2b80f',
    selectedOption: '#3daa2d',
    csv: '#207341',

    boxShadow: '#e4e4e4',

    lightGrey: '#e4e4e4',
    secondLightGrey: '#efefef',
    grey: '#adadad',
    darkGrey: '#6c757d',
  },
  fonts: {
    regular: 'Ubuntu',
    medium: 'Ubuntu-Medium',
    bold: 'Ubuntu-Bold',
  },
  fontSizes: {
    label: '1.8rem',
    pageLabel: '2.4rem',
    input: '1.8rem',
    logon: '2.4rem',
    button: '1.6rem',
    small: '1.2rem',
    default: '1.4rem',
    meddium: '1.5rem',
    large: '1.6rem',
    xLarge: '1.8rem',
  },
  weight: {
    td: '400',
    tableLabel: '1000',
  },
  radius: {
    meddium: '0.8rem',
    default: '0.6rem',
    small: '0.4rem',
  },
  shadows: {
    strong: '0 0.5rem 2rem rgba(0, 0, 0, 0.3)',
    default: '0 0.5rem 2rem rgba(0, 0, 0, 0.08)',
    flat: '0 0.2rem 0.2rem rgba(0, 0, 0, 0.08)',
  },
} as const;
