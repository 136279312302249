import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';

import Loading from '~/components/Loading';

const SignIn = lazy(() => import('~/pages/SignIn'));
const Forgot = lazy(() => import('../pages/ResetPassword/Forgot'));
const Code = lazy(() => import('../pages/ResetPassword/Code'));
const Password = lazy(() => import('../pages/ResetPassword/Password'));

export default function AuthRoutes() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route index element={<SignIn />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/code" element={<Code />} />
        <Route path="/password" element={<Password />} />
      </Routes>
    </Suspense>
  );
}
